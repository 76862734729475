import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import "../../styles/MainPage/MainVideo.css";
import Image1 from "../../images/Main/1.webp";
import Image2 from "../../images/Main/2.webp";
import Image3 from "../../images/Main/3.webp";
import Image4 from "../../images/Main/4.webp";
import Image5 from "../../images/Main/5.webp";
import Image6 from "../../images/Main/6.webp";
import Image7 from "../../images/Main/7.webp";
import Image8 from "../../images/Main/8.webp";
import Image9 from "../../images/Main/9.webp";
import Image10 from "../../images/Main/10.webp";
import Image12 from "../../images/Main/12.webp";
import Image13 from "../../images/Main/13.webp";
import Image14 from "../../images/Main/14.webp";


const images = [
	
	Image1, Image2, Image3, Image4, Image5, Image6, Image7, 
	Image8, Image9, Image10, Image12, Image13, Image14
  ];
  
  const MainVideo = () => {
	return (
	  <div className="main-video-div">
		<Slide
		  autoplay={true}
		  arrows={false}
		  duration={4000}
		  transitionDuration={1500}
		  pauseOnHover={false}
		  easing="ease"
		>
		  {images.map((image, index) => (
			<div key={index} className="main-video">
			  <div
				style={{
					backgroundImage: `url(${image})`,
					backgroundPosition: "center 60%", 
				}}
			  >
			  </div>
			</div>
		  ))}
		</Slide>
	  </div>
	);
  }
  
  export default MainVideo;

