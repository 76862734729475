import Kornelia_Kołodziejska from "../../images/Team/Zarzad/Kornelia_Kołodziejska.jpg"
import Kamil_Polowczyk from "../../images/Team/Kamil_Polowczyk.jpg"
import Marcin_Płatek from "../../images/Team/Zarzad/Marcin_Płatek.jpg"
import Magda_Sienska from "../../images/Team/Zarzad/ZK_Magda_Sieńska.jpg"
import Grzegorz_Radzikowski from "../../images/Team/Zarzad/K_Grzegorz_Radzikowski.jpg"
interface Person {
    img?: any,
    name: string,
    description?: string,
	email?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let kontakt_os: Person[] = [
	{img: Magda_Sienska, name:"Magdalena Sieńska", description: "Liderka dz. Operacyjnego", email: " magdalena.sienska@wutracing.pl"},
	{img: Marcin_Płatek, name:"Marcin Płatek", description:"Prezes", email: "marcin.platek@wutracing.pl"},
	{img: Grzegorz_Radzikowski, name: "Grzegorz Radzikowski", description:"Lider dz. Technicznego", email: "grzegorz.radzikowski@wutracing.pl"}
]

export type {Dzial, Person};
export default {kontakt_os};