//PR
import	Michał_Wiśniewski	from "../../images/Team/PR/Michał_Wiśniewski.jpg"
import	ZK_Magda_Sieńska	from "../../images/Team/PR/ZK_Magda_Sieńska.jpg"

//Silnik		
import	Igor_Syska	from "../../images/Team/Silnik/Igor_Syska.jpg"
import	Jakub_Dzikowski	from "../../images/Team/Silnik/Jakub_Dzikowski.jpg"
import	Jakub_Florczak	from "../../images/Team/Silnik/Jakub_Florczak.jpg"
import	Mateusz_Makarewicz	from "../../images/Team/Silnik/Mateusz_Makarewicz.jpg"
import	Michał_Milaniuk	from "../../images/Team/Silnik/Michał_Milaniuk.jpg"
import	Mikołaj_Klonowski	from "../../images/Team/Silnik/Mikołaj_Klonowski.jpg"
import	Przemysław_Karolak	from "../../images/Team/Silnik/Przemysław_Karolak.jpg"
import	ZK_Franciszek_Kajdzik	from "../../images/Team/Silnik/ZK_Franciszek_Kajdzik.jpg"
import	K_Kamil_Piszcz	from "../../images/Team/Silnik/K_Kamil_Piszcz.jpg"

//Chassis		
import	Aleksander_Abramowicz	from "../../images/Team/Chassis/Aleksander_Abramowicz.jpg"
import	Filip_Polański	from "../../images/Team/Chassis/Filip_Polański.jpg"
import	Igor_Stępień	from "../../images/Team/Chassis/Igor_Stępień.jpg"
import	Jan_Czech	from "../../images/Team/Chassis/Jan_Czech.jpg"
import	ZK_Grzegorz_Spruch	from "../../images/Team/Chassis/ZK_Grzegorz_Spruch.jpg"

//Zarząd		
import	Marcin_Platek	from "../../images/Team/Zarzad/Marcin_Płatek.jpg"
import	Mateusz_Drabarek	from "../../images/Team/Zarzad/Mateusz_Drabarek.jpg"

//Zawias		
import	Adam_Chiliński	from "../../images/Team/Zawieszenie/Adam_Chiliński.jpg"
import	Andrzej_Graska	from "../../images/Team/Zawieszenie/Andrzej_Graska.jpg"
import	Grzegorz_Siedlecki	from "../../images/Team/Zawieszenie/Grzegorz_Siedlecki.jpg"
import	K_Tomasz_Kołakowski	from "../../images/Team/Zawieszenie/K_Tomasz_Kołakowski.jpg"
import	Maria_Sirko	from "../../images/Team/Zawieszenie/Maria_Sirko.jpg"
import	Mateusz_Krajewski	from "../../images/Team/Zawieszenie/Mateusz_Krajewski.jpg"
import	Miłosz_Mościcki	from "../../images/Team/Zawieszenie/Miłosz_Mościcki.jpg"
import	Norbert_Kołek	from "../../images/Team/Zawieszenie/Norbert_Kołek.jpg"
import	ZK_Ian_Gjellebol	from "../../images/Team/Zawieszenie/ZK_Ian_Gjellebol.jpg"

import	Kamil_Polowczyk	from "../../images/Team/Kamil_Polowczyk.jpg"

//Aero
import	Aleksandra_Sadowska	from "../../images/Team/Aero/Aleksandra_Sadowska.jpg"
import	Benedykt_Jeż	from "../../images/Team/Aero/Benedykt_Jeż.jpg"
import	Iga_Gamska	from "../../images/Team/Aero/Iga_Gamska.jpg"
import	Jakub_Pacocha	from "../../images/Team/Aero/Jakub_Pacocha.jpg"
import	Łukasz_Rybakowski	from "../../images/Team/Aero/Łukasz_Rybakowski.jpg"
import	Michał_Żyto	from "../../images/Team/Aero/Michał_Żyto.jpg"
import	Wojciech_Baran	from "../../images/Team/Aero/Wojciech_Baran.jpg"
import	ZK_Tomasz_Polski	from "../../images/Team/Aero/ZK_Tomasz_Polski.jpg"
	
//Elektronika	
import	Błażej_Molas	from "../../images/Team/Elektronika/Błażej_Molas.jpg"
import	Jakub_Marcinowski	from "../../images/Team/Elektronika/Jakub_Marcinowski.jpg"
import	Jan_Sidor	from "../../images/Team/Elektronika/Jan_Sidor.jpg"
import	K_Grzegorz_Radzikowski	from "../../images/Team/Elektronika/K_Grzegorz_Radzikowski.jpg"
import	Kacper_Kowalski	from "../../images/Team/Elektronika/Kacper_Kowalski.jpg"
import	Patrycja_Woźniak	from "../../images/Team/Elektronika/Patrycja_Woźniak.jpg"

//Fundraising		

//Logistyka		
import	Mateusz_Giermek	from "../../images/Team/Logistyka/Mateusz_Giermek.jpg"
import	Szymon_Modzelewski	from "../../images/Team/Logistyka/Szymon_Modzelewski.jpg"

import	brak	from "../../images/Team/default.svg"




interface Person {
    img?: any,
    name: string,
    description?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let zarzad: Person[] = [
    {img: Marcin_Platek, name:"Marcin Płatek", description:"Prezes"},
    {img: ZK_Magda_Sieńska, name: "Magdalena Sieńska", description:"Wiceprezes ds. organizacyjnych"},
    {img: Jakub_Pacocha, name: "Jakub Pacocha", description:"Wiceprezes ds. finansowych"},
    {img: K_Grzegorz_Radzikowski, name: "Grzegorz Radzikowski", description:"Wiceprezes ds. technicznych"}
]


let aerodynamika: Dzial = {
    nameDzial: "DZIAŁ AERODYNAMIKI",
    leader: {img: ZK_Tomasz_Polski, name: "Tomasz Polski",description:"Koordynator działu Aerodynamiki"},
    persons: [
		{img: Iga_Gamska, name: "Iga Gamska", description: "Zastępca koordynatora"},
    {img: Wojciech_Baran, name: "Wojciech Baran"},
    {img: brak, name: "Kamil Grabowski"},
    {img: Benedykt_Jeż, name: "Benedykt Jeż"},
    {img: brak, name: "Stefan Józegowicz-Okonkwo"},
    {img: brak, name: "Jakub Krajewski"},
    {img: brak, name: "Filip Maziarka"},
    {img: brak, name: "Miłosz Niedziela"},
    {img: brak, name: "Szymon Rosiński"},
    {img: Łukasz_Rybakowski, name: "Łukasz Rybakowski"},
    {img: Aleksandra_Sadowska, name: "Aleksandra Sadowska"},
    {img: ZK_Magda_Sieńska, name: "Magdalena Sieńska"},
    {img: brak, name: "Antoni Wójcik"},
    {img: Michał_Żyto, name: "Michał Żyto"},
    ]
}

let chassis: Dzial = {
    nameDzial: "DZIAŁ CHASSIS",
    leader: {img: ZK_Grzegorz_Spruch, name: "Grzegorz Spruch", description: "Koordynator działu Chassis"},
    persons: [
		{img: Kamil_Polowczyk, name:"Kamil Polowczyk", description: "Zastępca koordynatora"},
    {img: Aleksander_Abramowicz, name: "Aleksander Abramowicz"},
    {img: brak, name: "Mikołaj Cieślak"},
    {img: Jan_Czech, name: "Jan Czech"},
    {img: brak, name: "Bartłomiej Misiurski"},
    {img: brak, name: "Michał Morawski"},
    {img: brak, name: "Krzysztof Niebylski"},
    {img: Filip_Polański, name: "Filip Polański"},
    {img: Igor_Stępień, name: "Igor Stępień"},

		//{img: Krzysztof_Żarnowski, name: "Krzysztof Żarnowski"},
    ]
}

let zawieszenie: Dzial = {
    nameDzial: "DZIAŁ ZAWIESZENIA",
    leader: {img: K_Tomasz_Kołakowski, name:"Tomasz Kołakowski", description:"Koordynator działu zawieszenia"},
    persons: [
		{img: ZK_Ian_Gjellebol, name: "Ian Gjellebol", description: "Zastępca koordynatora"},
    {img: brak, name: "Dominik Werpachowski"},
    {img: Adam_Chiliński, name: "Adam Chiliński"},
    {img: brak, name: "Damian Czerwiec"},
    {img: Andrzej_Graska, name: "Andrzej Graska"},
    {img: Norbert_Kołek, name: "Norbert Kołek"},
    {img: Mateusz_Krajewski, name: "Mateusz Krajewski"},
    {img: Miłosz_Mościcki, name: "Miłosz Mościcki"},
    {img: brak, name: "Mieczysław Pankiewicz"},
    {img: brak, name: "Tamara Saganek"},
    {img: Grzegorz_Siedlecki, name: "Grzegorz Siedlecki"},
    {img: Maria_Sirko, name: "Maria Sirko"},
    {img: brak, name: "Maksymilian Woźniak"},

    ]
}

let elektronika: Dzial = {
    nameDzial: "DZIAŁ ELEKTRONIKI",
    leader: {img: Błażej_Molas, name: "Błażej Molas", description:"Koordynator działu elektroniki"},
    persons: [
		{img: Mateusz_Drabarek, name: "Mateusz Drabarek", description: "Zastępca koordynatora"},
    {img: brak, name: "Karolina Ratajczyk"},
    {img: brak, name: "Abdulrahman Abdullah"},
    {img: Kacper_Kowalski, name: "Kacper Kowalski"},
    {img: Jakub_Marcinowski, name: "Jakub Marcinowski"},
    {img: brak, name: "Katarzyna Matejuk"},
    {img: brak, name: "Bartosz Pietrzak"},
    {img: brak, name: "Harshith Varma Salibilla"},
    {img: brak, name: "Nadia Serafin"},
    {img: Jan_Sidor, name: "Jan Sidor"},
    {img: Patrycja_Woźniak, name: "Patrycja Woźniak"},
    ]
}

let silnik: Dzial = {
    nameDzial: "DZIAŁ SILNIKA",
    leader: {img: Michał_Milaniuk, name: "Michał Milaniuk", description:"Koordynator działu silnika"},
    persons: [
		{img: K_Kamil_Piszcz, name: "Kamil Piszcz", description: "Zastępca koordynatora"},
    {img: brak, name: "Mario Blazevski"},
    {img: brak, name: "Jakub Czarzasty"},
    {img: Jakub_Dzikowski, name: "Jakub Dzikowski"},
    {img: Jakub_Florczak, name: "Jakub Florczak"},
    {img: ZK_Franciszek_Kajdzik, name: "Franciszek Kajdzik"},
    {img: Przemysław_Karolak, name: "Przemysław Karolak"},
    {img: brak, name: "Kacper Karpiński"},
    {img: Mikołaj_Klonowski, name: "Mikołaj Klonowski"},
    {img: brak, name: "Maciej Krop"},
    {img: Mateusz_Makarewicz, name: "Mateusz Makarewicz"},
    {img: brak, name: "Michał Mazur"},
    {img: Igor_Syska, name: "Igor Syska"},
    {img: brak, name: "Marcel Szpanel"},
    ]
}

let operacyjnyLider: Person = {img: ZK_Magda_Sieńska, name: "Magdalena Sieńska", description:"Lider działu operacyjnego"}

let pr: Dzial = {
    nameDzial: "DZIAŁ PR",
    leader: {img: ZK_Magda_Sieńska, name: "Magdalena Sieńska", description: "Koordynator działu PR"},
    persons: [
      {img: Jakub_Pacocha, name: "Jakub Pacocha", description: "Zastępca koordynatora"},
      {img: brak, name: "Beniamin Buchman"},
      {img: brak, name: "Jakub Kotowicz"},
      {img: brak, name: "Igor Kotłowski"},
      {img: brak, name: "Adam Krawczyk"},
      {img: brak, name: "Dagmara Legień"},
      {img: brak, name: "Aleksandra Mulewicz"},
      {img: Michał_Wiśniewski, name: "Michał Wiśniewski"},
      {img: brak, name: "Patrycja Wójcik"},
    ]
}

let logistyka: Dzial = {
    nameDzial: "DZIAŁ LOGISTYKI",
    leader: {img: Mateusz_Giermek, name: "Mateusz Giermek", description: "Koordynator działu logistyki"},
    persons: [
		{img: Szymon_Modzelewski, name: "Szymon Modzelewski", description: "Zastępca koordynatora"},
    {img: brak, name: "Aleksandra Breś"},
    {img: brak, name: "Bartłomiej Poławski"},
    {img: brak, name: "Antoni Starczynowski"},
    {img: brak, name: "Maciej Stradowski"},
    ]
}

let fundraising: Dzial = {
    nameDzial: "DZIAŁ FUNDRAISING",
    leader: {img: Jakub_Pacocha, name: "Jakub Pacocha", description: "Koordynator działu fundraisingu"},
    persons: [
    {img: ZK_Magda_Sieńska, name: "Magdalena Sieńska", description: "Zastępca koordynatora"},
    {img: brak, name: "Xawier Brzost"},
    {img: brak, name: "Łukasz Cwalina"},
    {img: brak, name: "Kuba Perkowski"},
    {img: brak, name: "Karolina Zawadzka"},
    ]
}

export type {Dzial, Person};
export default {fundraising, logistyka, pr, silnik, elektronika, aerodynamika, chassis, zawieszenie, zarzad, operacyjnyLider}
